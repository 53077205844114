import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { useIsMutating } from '@tanstack/react-query'
import {
  SelectStoreMutationKey,
  useMutationSelectStore,
} from '@/features/shop/services/ShoppingStore/mutations'
import {
  useIsGuestUser,
  useStoreId,
  useUserDefaultShoppingAddressId,
} from '@/features/account/services/User/hooks'
import { useValidNextParam } from '@/features/shared/hooks/useValidNextParam'
import { useGuestUserContext } from '@/features/shared/context/GuestUserContext'
import { useRouteToPromotion } from '@/features/shop/hooks/usePromotionLink'
import { type Promotion } from '@/features/shop/services/Campaigns/types'
import { type ShoppingStoreDetails } from '@/features/shop/services/ShoppingStore/types'
import { routes } from '@shared/constants/routes'

export const useGlobalHomeSelectStore = (promotion?: Promotion) => {
  const router = useRouter()
  const addressId = useUserDefaultShoppingAddressId()
  const { mutateAsync, isPending: isLoading, error } = useMutationSelectStore()
  const userStoreId = useStoreId()
  const isSelectingStore = Boolean(
    useIsMutating({
      mutationKey: [SelectStoreMutationKey],
    })
  )

  const nextRoute = useValidNextParam()
  const isGuestUser = useIsGuestUser()
  const { setGuestStoreCookie } = useGuestUserContext() ?? {}
  const routeToPromotion = useRouteToPromotion()

  // home page is a fallback route
  const route = nextRoute || routes.RETAILER_HOMEPAGE.url

  const handleRouting = useCallback(
    (destinationUrl?: string) => {
      if (destinationUrl) return router.push(destinationUrl)

      if (promotion) return routeToPromotion(promotion, route)

      return router.push(route)
    },
    [promotion, route, routeToPromotion, router]
  )

  const selectStore = useCallback(
    async (store: ShoppingStoreDetails, destinationUrl?: string) => {
      if (userStoreId === store?.id) return handleRouting(destinationUrl)

      if (isGuestUser) {
        setGuestStoreCookie?.(store)
      } else {
        await mutateAsync({ addressId, store })
      }

      return handleRouting(destinationUrl)
    },
    [
      userStoreId,
      handleRouting,
      isGuestUser,
      mutateAsync,
      addressId,
      setGuestStoreCookie,
    ]
  )

  return { isLoading, selectStore, isSelectingStore, error }
}
