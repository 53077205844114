import { Column } from '@shipt/design-system-layouts'
import { screenSizes } from '@shipt/design-system-themes'
import styled from 'styled-components'

export const QuickAccessCardStoreListWrapper = styled(Column)`
  position: relative;

  @media ${screenSizes.tablet} {
    min-height: 10.9375rem;

    .swiper {
      padding: 0 1rem 1rem;
    }
  }

  @media ${screenSizes.smDesktop} {
    .swiper {
      padding-left: 0;
    }
  }
`

export const QuickAccessCardStoreListStoreCardContainer = styled.div`
  @media ${screenSizes.tablet} {
    position: absolute;
    left: -0.9375rem;
    right: 0;
  }

  @media ${screenSizes.smDesktop} {
    left: -0.5rem;
  }

  .NavButton-left {
    margin-left: 1.5rem;
  }
`
