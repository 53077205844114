import { type ShoppingStoreDetails } from '@/features/shop/services/ShoppingStore/types'
import styled from 'styled-components'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { useGlobalHomeSelectStore } from '@/features/shop/components/GlobalHomePage/useGlobalHomeSelectStore'
import { Carousel } from '@/features/shop/components/Carousel'
import { useMediaQuery } from '@/features/shared/hooks/useMediaQuery'
import { BasicShadowHover } from '@/features/shared/styles/effects'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  trackAvailableStoreClicked,
  trackAvailableStoreViewed,
} from '@/features/shared/analytics/selectStore'
import { Box, Column } from '@shipt/design-system-layouts'
import { LoadingIndicator } from '@/features/shared/elements/LoadingIndicator'
import { BREAKPOINTS, screenSizes } from '@shipt/design-system-themes'
import { Body } from '@shipt/design-system-typography'
import { NewBadge } from '@/features/shared/elements/Badges/NewBadge'
import { useExperiment } from '@/features/shared/services/Experiments/hooks'
import { ExperimentType } from '@/features/shared/services/Experiments/constants'
import { type SwiperOptions } from 'swiper/types'
import { TransparentButton } from '@/features/shared/elements/Buttons'
import { useFeatureFlags } from '@/features/shared/services/FeatureFlags/useFeatureFlags'
import { useIsUserC360 } from '@/features/account/services/User/hooks'

type Props = {
  storesList: ShoppingStoreDetails[]
}

const swiperBreakpoints: SwiperOptions['breakpoints'] = {
  [BREAKPOINTS.MEDIUM]: {
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
  },
}

export const QuickAccessCardStoreList = ({ storesList }: Props) => {
  const { selectStore, isLoading } = useGlobalHomeSelectStore()
  const [pendingStoreLocationId, setPendingStoreLocationId] = useState(0)
  const { screenSm } = useMediaQuery()
  const { active: noMarkupsActive } = useExperiment(
    ExperimentType.NO_MARKUP_TEST
  )
  const {
    flags: { c360_nmu },
  } = useFeatureFlags()
  const isCircle360 = useIsUserC360()

  const trackAvailableStores = useCallback(() => {
    Object.values(storesList).forEach((store, idx) =>
      trackAvailableStoreViewed({
        storeId: store.id,
        storeName: store.name,
        index: idx,
        displaySublocation: 'store_list_carousel',
      })
    )
  }, [storesList])

  useEffect(() => {
    trackAvailableStores()
  }, [trackAvailableStores])

  const shouldWrap = storesList?.length <= 8

  const renderStoreList = useCallback(
    (list: Props['storesList']) => {
      return list?.map((store, index) => {
        const handleStoreClicked = () => {
          setPendingStoreLocationId(store.id)
          selectStore(store)
          trackAvailableStoreClicked({
            storeId: store.id,
            storeName: store.name,
            index,
            displaySublocation: 'store_list_carousel',
          })
        }

        const {
          id,
          name,
          circular_image,
          recently_launched,
          store_shelf_pricing,
        } = store ?? {}

        const showNoMarkupFeesCallout =
          !(isCircle360 && c360_nmu) && !noMarkupsActive && store_shelf_pricing
        const pricingCallout = 'No markups'

        return (
          <StoreCardWrapper
            key={id}
            onClick={() => handleStoreClicked()}
            aria-label={`click to select ${name}`}
            aria-describedby={`store-shelf-${id}`}
          >
            {isLoading && id === pendingStoreLocationId && (
              <LoadingIndicatorWrapper>
                <LoadingIndicator color="primary" />
              </LoadingIndicatorWrapper>
            )}
            <StoreImageWrapper>
              <Image
                width="32"
                height="32"
                src={circular_image}
                fallbackType="store"
                alt=""
              />
            </StoreImageWrapper>
            <StoreContent
              padding={[0, 0, 0, 'lg']}
              justify="start"
              recently_launched={recently_launched}
            >
              <CardText strong>{name}</CardText>
              {showNoMarkupFeesCallout && (
                <CardText variant="secondary" id={`store-shelf-${id}`}>
                  {pricingCallout}
                </CardText>
              )}
            </StoreContent>
            {recently_launched && (
              <Box marginLeft="lg">
                <NewBadge />
              </Box>
            )}
          </StoreCardWrapper>
        )
      })
    },
    [
      isCircle360,
      c360_nmu,
      noMarkupsActive,
      isLoading,
      pendingStoreLocationId,
      selectStore,
    ]
  )

  const StoreListCarouselRender = useMemo(() => {
    const matrixStoresList = storesList.reduce(
      (rows: ShoppingStoreDetails[][], key, index) => {
        index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1]?.push(key)
        return rows
      },
      []
    )
    return (
      <StoreListCarousel
        freeMode
        simulateTouch={screenSm}
        title="Stores near you"
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        breakpoints={swiperBreakpoints}
      >
        {matrixStoresList.map((storesOfTwos) => (
          <Column key={storesOfTwos[0]?.id}>
            {renderStoreList(storesOfTwos)}
          </Column>
        ))}
      </StoreListCarousel>
    )
  }, [renderStoreList, screenSm, storesList])

  if (shouldWrap) {
    return (
      <>
        <WrappedGroupWrapper>{renderStoreList(storesList)}</WrappedGroupWrapper>
        <MatrixGroupWrapper>{StoreListCarouselRender}</MatrixGroupWrapper>
      </>
    )
  }

  return StoreListCarouselRender
}

const StoreCardWrapper = styled(TransparentButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 15rem;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.gray300};
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  min-height: 4.125rem;

  &:hover {
    transition: none;
    border-color: transparent;
    ${BasicShadowHover}
  }

  @media ${screenSizes.smDesktop} {
    margin: 0.5rem;
  }
`
const CardText = styled(Body)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`
const StoreImageWrapper = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
`
const StoreContent = styled(Column)<{ recently_launched: boolean }>`
  width: ${({ recently_launched }) => (recently_launched ? '8rem' : '10.5rem')};
  padding: 0 0 0 1rem;
`
const LoadingIndicatorWrapper = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`
const StoreListCarousel = styled(Carousel)`
  @media ${screenSizes.max_mobile} {
    margin: 0 -1rem;
  }
`
const WrappedGroupWrapper = styled.div`
  display: none;

  @media ${screenSizes.smDesktop} {
    display: flex;
    flex-wrap: wrap;
  }
`
const MatrixGroupWrapper = styled.div`
  @media ${screenSizes.smDesktop} {
    display: none;
  }
`
