import { type StoresCarouselData } from '@/features/cms/components/CMS/types'
import { Headline } from '@shipt/design-system-typography'
import { QuickAccessCardStoreList } from '@/features/shop/components/GlobalHomePage/QuickAccessStoreList/QuickAccessCardStoreList'
import { GridLayoutContainer } from '@/features/shared/styles/layout'
import { useQueryShoppingStores } from '@/features/shop/services/ShoppingStore/queries'
import { type ShoppingStoreDetails } from '@/features/shop/services/ShoppingStore/types'
import { logInfo } from '@/features/shared/utils/logger'
import { useCMSTrackingEvents } from '@/features/cms/components/CMS/CMSContext'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'
import {
  QuickAccessCardStoreListWrapper,
  QuickAccessCardStoreListStoreCardContainer,
} from '@/features/shop/components/GlobalHomePage/QuickAccessStoreList/styles'
import { Row } from '@shipt/design-system-layouts'
import { useExperiment } from '@/features/shared/services/Experiments/hooks'
import { ExperimentType } from '@/features/shared/services/Experiments/constants'
import { StoresCarouselRedesign } from '@/features/cms/components/CMS/components/BrandAmplification/StoresCarouselRedesign'
import { useCMSShelvesContext } from '@/features/shared/context/CMSShelvesContext'
import { TextButton } from '@shipt/design-system-buttons'
import { useCMSLayoutContext } from '@/features/cms/components/CMS/CMSLayoutContext'
import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { useFeatureFlags } from '@/features/shared/services/FeatureFlags/useFeatureFlags'
import { useIsUserC360 } from '@/features/account/services/User/hooks'

/**
 * This component corresponds to the `stores_carousel` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/stores_carousel
 * - **Production** - https://cms.shipt.com/content-type/pool/stores_carousel
 */
export const StoresCarousel = ({
  content_type_id,
  id,
  data: { store_cards, heading, view_all_link, theme } = {},
}: StoresCarouselData) => {
  const { trackCMSElementClicked } = useCMSTrackingEvents()
  const cmsShelvesContext = useCMSShelvesContext()
  const layout = useCMSLayoutContext()
  const handleViewAll = (e: React.MouseEvent) => {
    cmsShelvesContext?.handleStoresCarouselViewAllClick(e)
    trackCMSElementClicked({
      link_name: 'view_all_stores',
      type: 'link',
    })
  }
  const isCircle360 = useIsUserC360()
  const {
    flags: { c360_nmu },
  } = useFeatureFlags()
  const { data: storesList } = useQueryShoppingStores()
  const { active: inNoMarkupsExp } = useExperiment(
    ExperimentType.NO_MARKUP_TEST
  )

  if (!store_cards?.length || !storesList?.delivery_stores.length) return null
  const deliveryStoresList = store_cards.reduce<ShoppingStoreDetails[]>(
    (acc, { data }) => {
      const store = storesList?.delivery_stores.find(
        (dStore) => dStore.id === data.retailer_id
      )
      if (!store) {
        logInfo(`provided store from CMS not available`, { cmsStore: data })
        return acc
      } else {
        return [
          ...acc,
          {
            ...store,
            ...data,
            id: data.retailer_id,
            circular_image: data.logo.src,
          },
        ]
      }
    },
    []
  )

  return theme === 'astro-dls' ? (
    <StoresCarouselRedesign
      handleViewAll={handleViewAll}
      content_type_id={content_type_id}
      id={id}
      deliveryStoresList={deliveryStoresList}
      heading={heading}
      hideNoMarkups={(isCircle360 && c360_nmu) || inNoMarkupsExp}
      view_all_link={view_all_link}
    />
  ) : (
    <CarouselWrapper {...getCMSWrapperProps({ content_type_id, id })}>
      <Row className="content" justify="space-between">
        {heading && <Headline size="lg">{heading}</Headline>}
        {view_all_link && (
          <TextButton
            onClick={(e: React.MouseEvent) => handleViewAll(e)}
            aria-label="View all stores"
          >
            {view_all_link.text}
          </TextButton>
        )}
      </Row>
      <QuickAccessCardStoreListWrapper
        className="content"
        margin={
          layout === 'marketplace_page_layout' ? [0, 0, 'xxl', 0] : undefined
        }
      >
        <QuickAccessCardStoreListStoreCardContainer>
          <QuickAccessCardStoreList storesList={deliveryStoresList} />
        </QuickAccessCardStoreListStoreCardContainer>
      </QuickAccessCardStoreListWrapper>
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled(GridLayoutContainer)`
  padding-top: 24px;

  @media ${screenSizes.tablet} {
    --content-max-width: 1280px;
  }

  @media ${screenSizes.smDesktop} {
    --content-max-width: 1080px;
  }
`
