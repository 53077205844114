import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type CalculateSavingsResponse,
  type CouponWithItemsUpcList,
  type SearchCouponProductsResponse,
  type SearchCouponsWithIdResponse,
} from '@/features/shop/services/Coupons/types'
import {
  getCouponsSavingsQueryKey,
  type CouponsSavingsQueryKey,
  type SearchCouponsByIdQueryKey,
  type SearchProductsWithBarcodesQueryKey,
} from '@/features/shop/services/Coupons/queries'
import {
  toCouponsById,
  resetCouponsUsageData,
} from '@/features/shop/services/Coupons/utils'
import {
  normalizeClippedCoupon,
  normalizeCoupon,
} from '@/features/shop/utils/normalizeCoupon'
import { apiPost } from '@/features/shared/utils/dataFetching'
import { normalizeProduct } from '@/features/shared/utils/normalizeProduct'

export const fetchCouponsSavings = async (
  context: QueryFunctionContext<CouponsSavingsQueryKey>
) => {
  const [, { currentActiveOrderId, storeParams, userId }] = context.queryKey
  const { metro_id, store_id, store_location_id } = storeParams ?? {}

  const response = await apiPost<CalculateSavingsResponse>({
    config: {
      url: 'coupon_service/v1/coupons.calculateSavings',
      params: {
        user_id: userId,
        ...(currentActiveOrderId
          ? { order_id: currentActiveOrderId }
          : { context: `${store_id}_${metro_id}_${store_location_id}` }),
      },
    },
    context,
    fetcherName: 'fetchCouponsSavings',
  })
  const { clipped_coupons = [], unclipped_coupons = [] } =
    response?.savings ?? {}
  const couponSavings = toCouponsById([
    ...clipped_coupons.map(normalizeClippedCoupon),
    ...unclipped_coupons.map(normalizeCoupon),
  ])
  /**
   * The coupon_service/v1/coupons.calculateSavings service does not return a coupon back in the API response
   * if it is no longer applicable to the cart/order. This means we need to make sure we don't remove any existing
   * coupons in the query cache and reset their usage data manually whenever the service returns no coupons.
   */
  const currentCoupons = context.client.getQueryData<typeof couponSavings>(
    getCouponsSavingsQueryKey({
      currentActiveOrderId,
      storeParams,
      userId,
    })
  )
  if (!Object.values(couponSavings).length) {
    return currentCoupons ? resetCouponsUsageData(currentCoupons) : {}
  }
  return { ...currentCoupons, ...couponSavings }
}

export const fetchSearchCouponsById = async (
  context: QueryFunctionContext<SearchCouponsByIdQueryKey>
): Promise<CouponWithItemsUpcList[]> => {
  const [, { storeParams, couponId }] = context.queryKey
  const data = await apiPost<SearchCouponsWithIdResponse>({
    config: {
      url: '/coupon_service/v1/coupons.search',
      data: {
        quotient_coupon_id_list: [couponId],
      },
    },
    options: { storeParams },
    fetcherName: 'fetchSearchCoupons',
  })
  return (data?.hits ?? []).map((coupon) => ({
    ...normalizeCoupon(coupon),
    itemsUpcList: coupon.items_upc12 || [],
  }))
}

export const fetchSearchProductsWithBarcodes = async (
  context: QueryFunctionContext<SearchProductsWithBarcodesQueryKey>
) => {
  const [, { couponId, ...restParams }] = context.queryKey
  const response = await apiPost<SearchCouponProductsResponse>({
    config: { url: '/barcodes/v2/search', data: restParams },
    fetcherName: 'fetchSearchProductsWithBarcodes',
  })
  const normalizedProducts = response.hits.map(({ product }) => {
    return normalizeProduct({
      ...product,
      coupon_id: couponId,
    })
  })
  // we only want to store products with featured promotion if all the
  // products have featured promotion
  return normalizedProducts.some((p) => Boolean(p.couponId))
    ? normalizedProducts.filter((p) => Boolean(p.couponId))
    : normalizedProducts
}
