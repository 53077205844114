import { normalizeCoupon } from '@/features/shop/utils/normalizeCoupon'
import {
  emailValidation,
  studentEmailFormValidation,
} from '@/features/shared/utils/validations'
import {
  type Content,
  type Section,
  type LayoutContentTypeID,
  type CmsContentMappedComponents,
} from '@/features/cms/components/CMS/types'
import {
  criteoApi,
  criteoLoadBeaconForProducts,
} from '@/features/shared/analytics/criteo'
import { isProduction } from '@/features/shared/utils/environment'
import { logError } from '@/features/shared/utils/logger'
import { isOnServer } from '@shared/constants/util'
import { routes } from '@shared/constants/routes'

export const singleEntryFormValidations = {
  EMAIL_VALIDATION: emailValidation,
  STUDENT_EMAIL_VALIDATION: studentEmailFormValidation,
  DEFAULT: emailValidation,
}

export const getCouponsFromCMSContent = (content: Content) => {
  const coupons =
    content.content_type_id === 'product_shelf'
      ? content.data.coupons ?? []
      : []
  return coupons.map(normalizeCoupon)
}

export const loadBeacons = (section: Section) => {
  section.forEach((data) => {
    if (
      data.content_type_id === 'product_shelf' &&
      data.metadata?.read_operation_info?.aggregate_onLoadBeacons
    ) {
      criteoLoadBeaconForProducts(
        data.metadata.read_operation_info.aggregate_onLoadBeacons
      )
    } else if (
      data.content_type_id === 'ad_banner' &&
      data.metadata.read_operation_info.load_beacon
    ) {
      criteoApi(data.metadata.read_operation_info.load_beacon)
    }
  })
}

function shouldLogMissingCmsData() {
  return (
    // missing components in Preview is expected
    // we will only log for code running on the client
    isProduction && // only care about production
    !isOnServer() &&
    !window.location.pathname.includes(routes.CMS_PREVIEW_PAGE.url)
  )
}

export function getComponentForContentTypeId({
  components,
  contentTypeId,
}: {
  components: Partial<CmsContentMappedComponents>
  contentTypeId: Content['content_type_id']
}) {
  const Component = components[contentTypeId]

  if (!Component && shouldLogMissingCmsData()) {
    logError('CMS: Component not found for content_type_id', { contentTypeId })
  }

  return Component
}

export const cmsLayoutTypes: string[] = [
  'page_layout',
  'global_home_page_layout',
  'retailer_home_page_layout',
  'marketplace_page_layout',
] satisfies LayoutContentTypeID[]

export function isValidLayoutContentTypeID(contentTypeId: LayoutContentTypeID) {
  const hasCmsLayoutType = cmsLayoutTypes.includes(contentTypeId)

  if (!hasCmsLayoutType && shouldLogMissingCmsData()) {
    logError('CMS: Layout not found for content_type_id', { contentTypeId })
  }

  return hasCmsLayoutType
}
