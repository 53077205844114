import { isValidRoute } from '@shared/constants/routes'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import dompurify from 'isomorphic-dompurify'

export const useValidNextParam = () => {
  const { query } = useRouter()
  const { next = '' } = query
  return useMemo(() => {
    if (!next || typeof next !== 'string') return
    const sanitizedNext = dompurify.sanitize(next)
    const [nextPath] = sanitizedNext.split('?')
    if (isValidRoute(nextPath || '')) return sanitizedNext
  }, [next])
}
