import styled, { css } from 'styled-components'
import { ScrollIntoView } from '@/features/shared/components/ScrollIntoView'
import { DISPLAY_FORMAT } from '@/features/shared/constants/analytics'
import { SkeletonConsumer } from '@/features/shared/components/SkeletonLoader'
import { GridLayoutContainer } from '@/features/shared/styles/layout'
import { screenSizes } from '@shipt/design-system-themes'
import { Body, Headline } from '@shipt/design-system-typography'
import { ButtonLink } from '@/features/shared/elements/Link/Buttons'
import { type FullWidthBannerData } from '@/features/cms/components/CMS/types'
import { useCMSTrackingEvents } from '@/features/cms/components/CMS/CMSContext'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'
import { Column } from '@shipt/design-system-layouts'
import { type PromoCardEvent } from '@/features/shared/analytics/promoCard'

type Props = FullWidthBannerData & { index: number }

/**
 * This component corresponds to the `full_width_banner` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/full_width_banner
 * - **Production** - https://cms.shipt.com/content-type/pool/full_width_banner
 */
export const FullWidthBanner = ({
  id,
  content_type_id,
  data,
  name,
  index: shelfIndex,
  metadata,
}: Props) => {
  const {
    heading,
    subheading,
    web_image,
    mobile_image,
    cta_link,
    banner_height,
    logo,
    cta_treatment = 'primary',
    heading_text_color,
    subheading_text_color,
  } = data
  const {
    trackCMSElementViewed,
    trackCMSElementClicked,
    trackCMSPromoCardViewed,
    trackCMSPromoCardClicked,
  } = useCMSTrackingEvents()

  const getPromoCardProps = (): Omit<PromoCardEvent, 'location'> => {
    const promotionCategoryId =
      metadata?.read_operation_info?.source_object?.object?.promotion_params
        ?.category_id
    const promotionId =
      metadata?.read_operation_info?.source_object?.object?.promotion_id
    return {
      content: heading,
      creative: data.web_image.src,
      display_format: DISPLAY_FORMAT.HORIZONTAL,
      display_sublocation: 'shelf',
      list_index: 0,
      next_location: cta_link?.url,
      ...(promotionCategoryId && {
        promotion_category_id: String(promotionCategoryId),
      }),
      ...(promotionId && { promotion_id: promotionId }),
      promotion_name: name,
      promotion_type_name: content_type_id,
      shelf_index: shelfIndex,
      source: metadata?.read_operation_info?.source,
    }
  }

  const handleClick = () => {
    trackCMSElementClicked({
      type: 'link',
      display_format: 'top',
      display_sublocation: id,
      column_index: 0,
      link_name: name,
      grid_index: 0,
      lineage: cta_link?.text ?? '',
    })

    trackCMSPromoCardClicked(getPromoCardProps())
  }

  const handleOnInView = () => {
    trackCMSElementViewed({
      next_location: cta_link?.url,
      shelf_index: shelfIndex,
      // For the shelf-related CMS components, the DS team would like for us to continue sending the shelf's heading/title.
      shelf_name: heading,
      link_name: cta_link?.text,
      list_index: 0,
      display_format: DISPLAY_FORMAT.HORIZONTAL,
      content: heading,
    })
    trackCMSPromoCardViewed(getPromoCardProps())
  }

  const getButtonProps = (type: string) => {
    switch (type) {
      case 'primary-green':
        return {
          concept: 'accent',
        } as const
      case 'inverse':
        return {
          surface: 'inverse',
        } as const
      case 'primary':
      default:
        return {
          variant: 'primary',
        } as const
    }
  }

  return (
    <SkeletonConsumer height={400}>
      <ScrollIntoView onInView={handleOnInView}>
        <GridLayoutContainer>
          <HeroLayoutContainer
            {...getCMSWrapperProps({ content_type_id, id })}
            $bannerHeight={banner_height}
            className="fullWidth contentAtSmDesktop"
          >
            <MobileImage
              src={mobile_image.src}
              alt={mobile_image.alt}
              fill
              sizes="720px"
              priority
            />
            <NonMobileImage
              src={web_image.src}
              alt={web_image.alt}
              fill
              sizes="1280px"
              priority
            />
            <Column
              align="center"
              padding={{ base: ['md', 0], lg: ['lg', 0] }}
              spacing="lg"
            >
              {banner_height === 'tall' && logo && (
                <StoreLogo
                  height={110}
                  width={110}
                  src={logo.src}
                  alt={logo.alt}
                />
              )}
              <Column>
                <Heading
                  size="xl"
                  as="h2"
                  align="center"
                  $heading_color={heading_text_color}
                >
                  {heading}
                </Heading>
                {subheading && (
                  <Subheading
                    size="xl"
                    align="center"
                    $subheading_color={subheading_text_color}
                  >
                    {subheading}
                  </Subheading>
                )}
              </Column>
              {cta_link && (
                <>
                  <MobileCtaLink
                    href={cta_link.url}
                    size="md"
                    onClick={handleClick}
                    opensInNewTab={cta_link.open_in_new_tab}
                    {...getButtonProps(cta_treatment)}
                  >
                    {cta_link.text}
                  </MobileCtaLink>
                  <NonMobileCtaLink
                    href={cta_link.url}
                    onClick={handleClick}
                    opensInNewTab={cta_link.open_in_new_tab}
                    {...getButtonProps(cta_treatment)}
                  >
                    {cta_link.text}
                  </NonMobileCtaLink>
                </>
              )}
            </Column>
          </HeroLayoutContainer>
        </GridLayoutContainer>
      </ScrollIntoView>
    </SkeletonConsumer>
  )
}

const HeroLayoutContainer = styled.div<{ $bannerHeight: 'short' | 'tall' }>(
  ({ $bannerHeight }) => css`
    position: relative;
    margin-bottom: 1rem;
    display: grid;
    place-items: center;
    min-height: 10rem;
    overflow: hidden;

    ${Column} {
      z-index: 1;
      max-width: 20rem;
    }

    @media ${screenSizes.tablet} {
      min-height: ${$bannerHeight === 'tall' ? 400 : 200}px;

      ${Column} {
        max-width: 31.25rem;
      }
    }

    @media ${screenSizes.smDesktop} {
      border-radius: 0.5rem;
    }
  `
)

const StoreLogo = styled(Image)`
  display: none;

  @media ${screenSizes.tablet} {
    display: block;
    width: 80px;
    height: 80px;
  }

  @media ${screenSizes.smDesktop} {
    width: 110px;
    height: 110px;
  }
`

const Heading = styled(Headline)<{ $heading_color?: string }>`
  color: ${({ $heading_color }) => $heading_color};
  font-size: 1.75rem;

  @supports (text-wrap: balance) {
    text-wrap: balance;
  }

  @supports not (text-wrap: balance) {
    white-space: pre-wrap;
  }

  @media ${screenSizes.tablet} {
    font-size: 3rem;
    line-height: 3.3rem;
  }
`

const Subheading = styled(Body)<{ $subheading_color?: string }>`
  color: ${({ $subheading_color }) => $subheading_color};
  font-size: 0.875rem;

  @media ${screenSizes.tablet} {
    font-size: 1rem;
  }
`

const MobileCtaLink = styled(ButtonLink)`
  min-width: 122px;

  @media ${screenSizes.smDesktop} {
    display: none;
  }
`

const NonMobileCtaLink = styled(ButtonLink)`
  min-width: 138px;

  @media ${screenSizes.max_mobile} {
    display: none;
  }

  @media ${screenSizes.only_tablet} {
    display: none;
  }
`

const BannerImage = styled(Image)`
  object-fit: cover;
  object-position: center;
`

const MobileImage = styled(BannerImage)`
  @media ${screenSizes.tablet} {
    display: none;
  }
`

const NonMobileImage = styled(BannerImage)`
  @media ${screenSizes.max_mobile} {
    display: none;
  }
`
