import {
  type AstroButtonData,
  type Link,
  type TakeoverBannerData,
} from '@/features/cms/components/CMS/types'
import { CMSErrorHandler } from '@/features/cms/components/CMS/CMSErrorHandler'
import styled from 'styled-components'
import { useHandleDialog } from '@/features/shared/state/Dialog/useHandleDialog'
import { GridLayoutContainer } from '@/features/shared/styles/layout'
import { Headline } from '@shipt/design-system-typography'
import { Image as NextImage } from '@/features/shared/elements/Image/NextImage'
import { Column } from '@shipt/design-system-layouts'
import { Button } from '@shipt/design-system-buttons'
import { DynamicRetailerSelectionDrawer } from '@/features/cms/components/CMS/components/RetailerSelectionDrawer/DynamicRetailerSelectionDrawer'
import { renderElementInverse } from '@/features/cms/components/CMS/RichTextRenderer'
import { ButtonLink } from '@/features/shared/elements/Link/Buttons'
import { ScrollIntoView } from '@/features/shared/components/ScrollIntoView'
import { useCMSTrackingEvents } from '@/features/cms/components/CMS/CMSContext'
import { screenSizes } from '@shipt/design-system-themes'

type CtaState =
  | { type: 'none' }
  | {
      type: 'action'
      buttonData: AstroButtonData
      actionContent: NonNullable<
        TakeoverBannerData['data']['action_content']
      >[0]
    }
  | {
      type: 'link'
      buttonData: AstroButtonData
      link: Link
    }

function getCtaState(cmsData?: TakeoverBannerData['data']): CtaState {
  if (!cmsData?.cta_button?.data) {
    return { type: 'none' }
  }
  const buttonData = cmsData.cta_button.data
  if (cmsData.action_content?.[0]) {
    return {
      type: 'action',
      buttonData,
      actionContent: cmsData.action_content[0],
    }
  }
  if (buttonData.link) {
    return {
      type: 'link',
      buttonData,
      link: buttonData.link,
    }
  }
  return { type: 'none' }
}

export const TakeoverBanner = ({
  content_type_id,
  id,
  data,
}: TakeoverBannerData) => {
  const { openDialog } = useHandleDialog()
  const { heading, subheading, disclaimer, foreground_logo } = data
  const { trackCMSElementViewed } = useCMSTrackingEvents()

  const ctaState = getCtaState(data)
  // if ctaState none, should we issue log warn? We aren't rendering a button if
  // certain conditions are not met
  const handleClick = () => {
    if (
      ctaState.type === 'action' &&
      ctaState.actionContent.content_type_id === 'retailer_selection_drawer'
    ) {
      openDialog(DynamicRetailerSelectionDrawer, ctaState.actionContent)
    }
  }

  const handleOnInView = () => {
    // what properties go here
    trackCMSElementViewed({
      shelf_index: 0,
      shelf_name: content_type_id,
      link_name: 'start_shopping',
      content: data.heading,
    })
  }
  try {
    return (
      <ScrollIntoView onInView={handleOnInView}>
        <HeroLayoutContainer bgColor={data.background_color}>
          {data.mobile_web_image && (
            <MobileImage
              className="fullWidth"
              src={data.mobile_web_image?.src}
              alt={data.mobile_web_image.alt}
              fill
              quality={100}
              priority
              sizes="100vw"
            />
          )}
          {data.desktop_web_image && (
            <DesktopImage
              className="fullWidth"
              src={data.desktop_web_image?.src}
              alt={data.desktop_web_image.alt}
              fill
              quality={90}
              priority
              sizes="100vw"
            />
          )}
          <ContentContainer align="center" spacing="md">
            <Column align="center" spacing="xs">
              {foreground_logo && (
                <ForegroundLogo
                  height={26}
                  width={150}
                  priority
                  src={foreground_logo?.src}
                  alt={foreground_logo?.alt}
                />
              )}
              <Headline size="xl" surface="inverse">
                {heading}
              </Headline>
              {subheading?.elements && (
                <>{subheading.elements.map(renderElementInverse)}</>
              )}
            </Column>
            {ctaState.type === 'action' && (
              <Button
                size="md"
                surface={ctaState.buttonData.surface}
                concept={ctaState.buttonData.concept}
                onClick={handleClick}
              >
                {ctaState.buttonData.text}
              </Button>
            )}
            {ctaState.type === 'link' && (
              <ButtonLink
                size="md"
                surface={ctaState.buttonData.surface}
                concept={ctaState.buttonData.concept}
                href={ctaState.link.url}
                opensInNewTab={ctaState.link.open_in_new_tab ?? false}
              >
                {ctaState.buttonData.text}
              </ButtonLink>
            )}
            {disclaimer?.elements.map(renderElementInverse)}
          </ContentContainer>
        </HeroLayoutContainer>
      </ScrollIntoView>
    )
  } catch (error) {
    return (
      <CMSErrorHandler error={error} contentTypeId={content_type_id} id={id} />
    )
  }
}

const MobileImage = styled(NextImage)`
  object-fit: cover;

  @media ${screenSizes.lgMobile} {
    display: none;
  }
`
const DesktopImage = styled(NextImage)`
  object-fit: cover;

  @media ${screenSizes.max_smMobile} {
    display: none;
  }
`
const ContentContainer = styled(Column)`
  position: relative;
`
const HeroLayoutContainer = styled(GridLayoutContainer)<{ bgColor?: string }>`
  place-items: center;
  height: 23.8rem;
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
`

const ForegroundLogo = styled(NextImage)`
  width: 150px;
  height: 26px;
`
