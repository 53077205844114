import { CMSSection } from '@/features/cms/components/CMS/CMSSection'
import {
  type CmsMappedComponents,
  type CoreLayoutContentTypes,
} from '@/features/cms/components/CMS/types'
import dynamic from 'next/dynamic'
import { type ComponentProps } from 'react'
import { FullWidthBanner } from '@/features/cms/components/CMS/components/Shelves/FullWidthBanner'
import { StoresCarousel } from '@/features/cms/components/CMS/components/StoresCarousel'
import { getCMSShelfLoader } from '@/features/cms/components/CMS/CMSLoaders'
import { TakeoverBanner } from '@/features/cms/components/CMS/components/TakeoverBanner'

/* PLOP_INJECT_DYNAMIC_IMPORT */
const DynamicHeaderBanner = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/BrandAmplification/HeaderBanner'
      )
    ).HeaderBanner,
  { loading: getCMSShelfLoader('header_banner') }
)

const DynamicPastOrdersShelf = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/PastOrdersShelf'
      )
    ).PastOrdersShelf,
  { loading: getCMSShelfLoader('past_orders_shelf') }
)
const DynamicPromoBanner = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/PromoBanner'))
      .PromoBanner,
  { loading: getCMSShelfLoader('promo_banner') }
)
const DynamicSmallNav = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/Shelves/SmallNav'))
      .SmallNav,
  { loading: getCMSShelfLoader('small_nav') }
)

const DynamicLargePromoCarousel = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/LargePromoCarousel'
      )
    ).LargePromoCarousel,
  { loading: getCMSShelfLoader('large_promo_carousel') }
)

const DynamicCampaignPromoBanner = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/CMSCampaignPromoBanner/CMSCampaignPromoBanner'
      )
    ).CMSCampaignPromoBanner,
  { loading: getCMSShelfLoader('campaign_promo_banner') }
)
const DynamicMultiRetailerProducts = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/MultiRetailerProductShelves'
      )
    ).MultiRetailerProductShelves,
  { loading: getCMSShelfLoader('multi_retailer_product_shelves') }
)
const DynamicHeroCardCarousel = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/HeroCardCarousel'
      )
    ).HeroCardCarousel,
  { loading: getCMSShelfLoader('hero_card_carousel') }
)
const DynamicSmallPromoCarousel = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/SmallPromoCarousel'
      )
    ).SmallPromoCarousel,
  { loading: getCMSShelfLoader('small_promo_carousel') }
)
const DynamicCMSCouponShelf = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/CMSCouponShelf'
      )
    ).CMSCouponShelf,
  { loading: getCMSShelfLoader('coupon_shelf') }
)
const DynamicCMSProductShelf = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/ProductShelf/CMSProductShelf'
      )
    ).CMSProductShelf,
  { loading: getCMSShelfLoader('product_shelf') }
)
const DynamicStandardNav = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/StandardNav'
      )
    ).StandardNav,
  { loading: getCMSShelfLoader('standard_nav') }
)
const DynamicAdBanner = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/CMSAdBanner'
      )
    ).AdBanner,
  { loading: getCMSShelfLoader('ad_banner') }
)
const DynamicEnhancedNav = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/EnhancedNav'
      )
    ).EnhancedNav,
  { loading: getCMSShelfLoader('enhanced_nav') }
)
const DynamicEnhancedProduct = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Shelves/EnhancedProduct'
      )
    ).EnhancedProduct,
  { loading: getCMSShelfLoader('enhanced_product') }
)

export const coreLayoutCmsComponents = {
  /* PLOP_INJECT_CMS_SECTION_COMPONENT */
  header_banner: DynamicHeaderBanner,
  ad_banner: DynamicAdBanner,
  campaign_promo_banner: DynamicCampaignPromoBanner,
  coupon_shelf: DynamicCMSCouponShelf,
  enhanced_nav: DynamicEnhancedNav,
  enhanced_product: DynamicEnhancedProduct,
  full_width_banner: FullWidthBanner,
  hero_card_carousel: DynamicHeroCardCarousel,
  large_promo_carousel: DynamicLargePromoCarousel,
  multi_retailer_product_shelves: DynamicMultiRetailerProducts,
  past_orders_shelf: DynamicPastOrdersShelf,
  product_shelf: DynamicCMSProductShelf,
  promo_banner: DynamicPromoBanner,
  small_nav: DynamicSmallNav,
  small_promo_carousel: DynamicSmallPromoCarousel,
  standard_nav: DynamicStandardNav,
  stores_carousel: StoresCarousel,
  takeover_banner: TakeoverBanner,
} satisfies CmsMappedComponents<CoreLayoutContentTypes['content_type_id']>

export const CoreLayoutCMSSection = (
  props: Omit<ComponentProps<typeof CMSSection>, 'components'>
) => <CMSSection {...props} components={coreLayoutCmsComponents} />
