import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { routes } from '@shared/constants/routes'
import { type Promotion } from '@/features/shop/services/Campaigns/types'
import { type SearchParams } from '@/features/shop/services/Search/types'
import { stringify } from '@/features/shared/utils/queryString'

export const useRouteToPromotion = () => {
  const router = useRouter()

  return useCallback(
    async (promotion: Promotion, fallbackUrl: string = routes.SEARCH.url) => {
      try {
        const promotionRoute =
          promotion?.promotion_params?.relative_link ??
          getSearchRouteWithPromotion(promotion)

        return router.push(promotionRoute ?? fallbackUrl)
      } catch {
        return router.push(fallbackUrl)
      }
    },
    [router]
  )
}

export const getSearchRouteWithPromotion = (
  promotion: Promotion
): string | null => {
  const { category_id, search_url, is_marketing_category } =
    promotion?.promotion_params ?? {}

  if (search_url) return search_url

  if (category_id) {
    const params: SearchParams = {
      query: '',
      [is_marketing_category ? 'promotion_category_id' : 'category_id']:
        category_id,
    }
    const stringifiedParams = stringify(params)
    return `${routes.FEATURED_PROMOTIONS.url}/${category_id}?${stringifiedParams}`
  }

  return null
}
