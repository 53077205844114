import styled from 'styled-components'
import { Badge } from '@shipt/design-system-badge'

// TODO: https://app.clickup.com/t/86b2y752t
// Need to add a square option in ASTRO to Badge to remove file
const NewBadgeStyles = styled(Badge)`
  border-radius: 0.25rem;
`

export const NewBadge = () => <NewBadgeStyles size="sm">New</NewBadgeStyles>
