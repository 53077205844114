import { useEffect } from 'react'
import {
  type CmsContentMappedComponents,
  type Section,
} from '@/features/cms/components/CMS/types'
import { CouponsContextProvider } from '@/features/shop/components/Coupons/CouponsContext'
import {
  getComponentForContentTypeId,
  getCouponsFromCMSContent,
  loadBeacons,
} from '@/features/cms/components/CMS/utils'
import { CMSContextProvider } from '@/features/cms/components/CMS/CMSContext'
import { DeferredShelf } from '@/features/cms/components/CMS/DeferredShelf'

export const CMSSection = ({
  section,
  components,
}: {
  section: Section
  components: Partial<CmsContentMappedComponents>
}) => {
  useEffect(() => {
    loadBeacons(section)
  }, [section])

  return (
    <>
      {section.map((data, idx) => {
        const { content_type_id, id } = data

        const Component = getComponentForContentTypeId({
          components,
          contentTypeId: content_type_id,
        })
        if (!Component) return null

        return (
          <DeferredShelf
            data={data}
            // It's possible for the same content to be used multiple times on
            // the same page, so we can't rely on the id alone.
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-${idx}`}
            contentTypeId={content_type_id}
          >
            {(resolvedData) => (
              <CMSContextProvider data={resolvedData}>
                <CouponsContextProvider
                  coupons={getCouponsFromCMSContent(resolvedData)}
                >
                  <Component {...resolvedData} index={idx} />
                </CouponsContextProvider>
              </CMSContextProvider>
            )}
          </DeferredShelf>
        )
      })}
    </>
  )
}
