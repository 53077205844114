import { createContext, useContext, useMemo } from 'react'

type CMSShelvesContextType = {
  handleStoresCarouselViewAllClick: (e: React.MouseEvent) => void
}

export const CMSShelvesContextProvider = ({
  children,
  handleStoresCarouselViewAllClick,
}: CMSShelvesContextType & {
  children: React.ReactNode
}) => {
  const value = useMemo(() => {
    return {
      handleStoresCarouselViewAllClick,
    }
  }, [handleStoresCarouselViewAllClick])

  return (
    <CMSShelvesContext.Provider value={value}>
      {children}
    </CMSShelvesContext.Provider>
  )
}

const CMSShelvesContext = createContext<CMSShelvesContextType | null>(null)

export const useCMSShelvesContext = () => useContext(CMSShelvesContext)
