import { GridLayoutContainer } from '@/features/shared/styles/layout'
import { IconButton, TextButton } from '@shipt/design-system-buttons'
import { Carousel } from '@shipt/design-system-carousel'
import { ArrowRightIcon } from '@shipt/design-system-icons'
import { screenSizes, spacingPx } from '@shipt/design-system-themes'
import styled from 'styled-components'
import { TransparentButton } from '@/features/shared/elements/Buttons'
import { Body } from '@shipt/design-system-typography'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { type ShoppingStoreDetails } from '@/features/shop/services/ShoppingStore/types'
import chunk from 'lodash/chunk'
import { type Link } from '@/features/cms/components/CMS/types'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'
import {
  trackAvailableStoreClicked,
  trackAvailableStoreViewed,
} from '@/features/shared/analytics/selectStore'
import { useGlobalHomeSelectStore } from '@/features/shop/components/GlobalHomePage/useGlobalHomeSelectStore'
import { ScrollIntoView } from '@/features/shared/components/ScrollIntoView'

const StoreTile = ({
  backgroundColor,
  showNewBadge,
  image,
  name,
  showNoMarkups,
  onSelectStore,
  onInView,
}: {
  backgroundColor: string
  showNewBadge: boolean
  image: string
  name: string
  showNoMarkups: boolean
  onSelectStore: () => void
  onInView: () => void
}) => {
  const cardCallout = showNoMarkups ? 'No markups' : ''

  return (
    <ScrollIntoView onInView={onInView}>
      <StoreButton
        onClick={onSelectStore}
        aria-label={`click to select ${name}`}
      >
        <BrowseTile backgroundColor={backgroundColor}>
          {showNewBadge && (
            <NewBadgeStyles as="span" surface="inverse" strong size="sm">
              New
            </NewBadgeStyles>
          )}
          <StoreLogo
            alt={`${name} store image`}
            src={image}
            fill
            sizes={`${screenSizes.tablet} 150px, 250px`}
            fallbackType="store"
          />
        </BrowseTile>
        <StoreNameWrapper>
          <StoreName>{name}</StoreName>
        </StoreNameWrapper>
        <Body size="md" variant="secondary">
          {cardCallout}
        </Body>
      </StoreButton>
    </ScrollIntoView>
  )
}

const ViewAllIcon = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent) => void
}) => (
  <IconButton
    onClick={onClick}
    aria-label="View all stores"
    variant="tertiary"
    icon={ArrowRightIcon}
    size="sm"
    className="tablet-button"
  />
)

export const StoresCarouselRedesign = ({
  heading,
  deliveryStoresList,
  hideNoMarkups,
  view_all_link,
  handleViewAll,
  content_type_id,
  id,
}: {
  hideNoMarkups: boolean
  heading?: string
  deliveryStoresList: ShoppingStoreDetails[]
  view_all_link?: Link
  handleViewAll: (e: React.MouseEvent) => void
  content_type_id: string
  id: string
}) => {
  const { selectStore } = useGlobalHomeSelectStore()
  const handleStoreClicked = (store: ShoppingStoreDetails, index: number) => {
    selectStore(store)
    trackAvailableStoreClicked({
      storeId: store.id,
      storeName: store.name,
      index,
      displaySublocation: 'store_list_carousel',
    })
  }

  const onInView = (store: ShoppingStoreDetails, index: number) => {
    trackAvailableStoreViewed({
      storeId: store.id,
      storeName: store.name,
      index,
      displaySublocation: 'store_list_carousel',
    })
  }

  const renderStoreTile = (store: ShoppingStoreDetails, storeIndex: number) => (
    <StoreTile
      key={store.id}
      image={store.circular_image}
      name={store.name}
      showNoMarkups={!hideNoMarkups && store.store_shelf_pricing}
      backgroundColor={store.background_color}
      showNewBadge={store.recently_launched}
      onInView={() => onInView(store, storeIndex)}
      onSelectStore={() => handleStoreClicked(store, storeIndex)}
    />
  )

  const DesktopCarousel = (
    <Carousel
      title={{ content: heading ?? 'Stores near you', isHidden: !heading }}
      trailing={
        view_all_link ? (
          <>
            <TextButton
              onClick={handleViewAll}
              aria-label="View all stores"
              className="desktop-button"
            >
              {view_all_link.text}
            </TextButton>
            <ViewAllIcon onClick={handleViewAll} />
          </>
        ) : undefined
      }
      className="stores-carousel-desktop"
      hasInlineControls
    >
      {deliveryStoresList.map(renderStoreTile)}
    </Carousel>
  )

  const MobileCarousel = (
    <Carousel
      title={{ content: heading ?? 'Stores near you', isHidden: !heading }}
      className="stores-carousel-mobile"
      trailing={
        view_all_link ? <ViewAllIcon onClick={handleViewAll} /> : undefined
      }
      hasInlineControls
    >
      {deliveryStoresList.length > 5
        ? chunk(deliveryStoresList, 2).map((tuple, tupleIndex) => (
            <Tuple key={tuple[0]?.id}>
              {tuple.map((store, storeIndex) =>
                renderStoreTile(store, tupleIndex * 2 + storeIndex)
              )}
            </Tuple>
          ))
        : deliveryStoresList.map(renderStoreTile)}
    </Carousel>
  )
  return (
    <CarouselWrapper {...getCMSWrapperProps({ content_type_id, id })}>
      {DesktopCarousel}
      {MobileCarousel}
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled(GridLayoutContainer)`
  @media ${screenSizes.tablet} {
    --content-max-width: 1280px;
  }

  @media ${screenSizes.smDesktop} {
    --content-max-width: 1080px;
  }

  & .stores-carousel-desktop {
    display: none;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @media ${screenSizes.lgMobile} {
      display: block;
      grid-column: full-width;

      & .desktop-button {
        display: none;
      }

      & .tablet-button {
        display: flex;
      }
    }

    @media ${screenSizes.tablet} {
      grid-column: content;

      & .desktop-button {
        display: flex;
      }

      & .tablet-button {
        display: none;
      }
    }
  }

  & .stores-carousel-mobile {
    grid-column: full-width;
    margin-bottom: 1rem;

    @media ${screenSizes.lgMobile} {
      display: none;
    }
  }
`

const Tuple = styled.div`
  display: grid;
  flex-grow: 1;
  grid-gap: ${spacingPx('sm')};
  grid-template-rows: 1fr 1fr;
`

const StoreLogo = styled(Image)`
  object-fit: contain;
`

const StoreButton = styled(TransparentButton)`
  flex-grow: 1;
  align-self: start;
  text-align: left;
  width: 100%;

  &:focus-visible {
    outline: solid 2px ${({ theme }) => theme.color.focus.default};
    border-radius: 12px;
  }
`

const BrowseTile = styled.div<{ backgroundColor: string }>`
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => `${backgroundColor}`};
  aspect-ratio: 16/9;
  display: flex;
  position: relative;
  border: 1px solid rgba(0 0 0 / 10%);

  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      float: left;
      padding-top: 56.25%;
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0 0 0 / 100%);
    border-radius: 7px;
    opacity: 0;
  }

  ${StoreButton}:hover &::after {
    opacity: 0.2;
  }
`

const NewBadgeStyles = styled(Body)`
  background-color: rgb(0 0 0 / 70%);
  border-radius: 0.25rem;
  padding: 0 0.175rem;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
`

const StoreName = styled(Body)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const StoreNameWrapper = styled.div`
  display: inline-grid;
`
