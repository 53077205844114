import { memo } from 'react'
import { type SkeletonProps } from '@/features/shared/components/SkeletonLoader/types'
import { range } from '@/features/shared/utils/range'
import { Skeleton } from '@shipt/design-system-loading'

/**
 The presentational component that can be used independently
 */
export const SkeletonLoader = memo(
  ({
    isLoading,
    children,
    width,
    height,
    className = '',
    surface = 'default',
    animation = true,
  }: SkeletonProps) => {
    if (isLoading) {
      return (
        <Skeleton
          animation={animation}
          className={className}
          width={width}
          height={height}
          surface={surface}
        />
      )
    }
    // ignore rule for children usage
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (children) return <>{children}</>

    return null
  }
)

type SkeletonLoadersProps = SkeletonProps & { count: number }

export const Skeletons = ({ count, ...restProps }: SkeletonLoadersProps) => (
  <>
    {range(count).map((v) => (
      <SkeletonLoader {...restProps} key={v} />
    ))}
  </>
)
