/*
    Creates an array of length "count", where each value is the current index in the array.
    This can be used to generate repeated items and supply list key values ONLY if the following are true:
    - The list is static and composed of identical items
    - There is no other unique, non-index, value that could be used to identify each item
    - No items will be added, removed, or have their order changed
*/

export const range = (count: number) =>
  Array.from({ length: count }, (_, i) => i)
