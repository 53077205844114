import { createContext, useContext, type ReactNode } from 'react'
import { type SkeletonProps } from '@/features/shared/components/SkeletonLoader/types'
import { SkeletonLoader } from '@/features/shared/components/SkeletonLoader/SkeletonLoader'

type ChildrenProps = { children: ReactNode }

type ProviderProps = Pick<SkeletonProps, 'isLoading'> & ChildrenProps

type ConsumerProps = Omit<SkeletonProps, 'isLoading'> & ChildrenProps

const LoadingContext = createContext(false)

/**
 Context provider to allow child components access to parent loading state
 */
export const SkeletonProvider = ({
  isLoading = false,
  children,
}: ProviderProps) => (
  <LoadingContext.Provider value={isLoading}>
    {children}
  </LoadingContext.Provider>
)

/**
 Context consumer requires context provider as parent to access isLoading prop
 This will have the same props structure as the component it wraps: SkeletonLoader
 */
export const SkeletonConsumer = ({ children, ...restProps }: ConsumerProps) => {
  const isLoading = useContext(LoadingContext)

  return (
    <SkeletonLoader {...restProps} isLoading={isLoading}>
      {children}
    </SkeletonLoader>
  )
}

export const useSkeletonLoaderContext = () => useContext(LoadingContext)
