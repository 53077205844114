import { urlPathnameToLocationName } from '@/features/shared/analytics/utils'
import { AnalyticsEvent } from '@shipt/analytics-member-web'
import { track } from '@/features/shared/analytics/trackingQueue'

type Args = {
  storeId: number
  storeName: string
  storeLocationId?: number
  isNew?: boolean
  index: number
  displaySublocation?: string
  promotionId?: number
  lastStore?: boolean
  openCart?: boolean
  content?: string
}

export const trackAvailableStoreViewed = ({
  storeId,
  storeName,
  storeLocationId,
  isNew,
  index,
  displaySublocation,
  promotionId,
  lastStore,
  openCart,
}: Args) => {
  track({
    eventName: AnalyticsEvent.AvailableStoreViewed,
    properties: {
      store_id: storeId,
      store_name: storeName,
      store_location_id: storeLocationId,
      is_new: isNew,
      location: urlPathnameToLocationName(),
      index,
      display_sublocation: displaySublocation,
      promotion_id: promotionId,
      last_store: lastStore,
      open_cart: openCart,
    },
  })
}

export const trackAvailableStoreClicked = ({
  storeId,
  storeName,
  storeLocationId,
  isNew,
  index,
  displaySublocation,
  promotionId,
  lastStore,
  openCart,
  content,
}: Args) => {
  track({
    eventName: AnalyticsEvent.AvailableStoreClicked,
    properties: {
      store_id: storeId,
      store_name: storeName,
      store_location_id: storeLocationId,
      is_new: isNew,
      location: urlPathnameToLocationName(),
      index,
      display_sublocation: displaySublocation,
      promotion_id: promotionId,
      last_store: lastStore,
      open_cart: openCart,
      ...(content && { content }),
    },
  })
}
