import {
  apiDelete,
  apiPatch,
  apiPost,
} from '@/features/shared/utils/dataFetching'
import {
  type QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  type ValidAddress,
  type BaseAddress,
  type DeleteAddressResponse,
  type AddressMutationBffResponse,
} from '@/features/account/services/Addresses/types'
import { addressQueryOptions } from '@/features/account/services/Addresses/queries'
import { useCustomerAddressesQuery } from '@/features/account/services/Addresses/graphql/customerAddresses.generated'
import { userIsDoNotShareSellTA } from '@/features/account/services/DataPrivacy/utils'
import { setClientOptOutState } from '@/features/account/services/DataPrivacy/fetchers'

const onOptOutSuccessHandler =
  (queryClient: QueryClient) =>
  async ({ user_opt_out }: { user_opt_out: boolean }) => {
    // BACKEND took care of opting out the user, FE needs to invalidate queries,  set window property and call opt-out endpoint to set cookies
    //
    if (user_opt_out) {
      await setClientOptOutState()
      return queryClient.invalidateQueries()
    }

    // no user opt out, so just invalidate existing address queries
    return Promise.all([
      queryClient.invalidateQueries({
        queryKey: [useCustomerAddressesQuery.rootKey],
      }),
      queryClient.invalidateQueries({
        queryKey: addressQueryOptions.queryKey,
      }),
    ])
  }

export const useMutationAddAddress = () => {
  const queryClient = useQueryClient()

  const checkUserOptOut = !userIsDoNotShareSellTA()
  return useMutation({
    mutationFn: async (address: ValidAddress) => {
      const resp = await apiPost<AddressMutationBffResponse>({
        config: {
          url: 'member-web-bff/v1/address',
          data: { address, check_user_opt_out: checkUserOptOut },
        },
        options: {
          ignoredMessages: [
            /address not in service area/i,
            'delivery instructions must be 400 characters or less',
            'Please enter a valid address',
            "too many addresses, you're allowed a maximum of 100",
          ],
        },
        fetcherName: 'useMutationAddAddressBff',
        tag: 'bff',
      })

      // satisfy typescript, as there are consumers that expect address
      // to be returned from this mutation
      return { ...resp.address, user_opt_out: resp.user_opt_out }
    },
    onSuccess: onOptOutSuccessHandler(queryClient),
  })
}
export const useMutationUpdateAddress = <
  TAddress extends Partial<BaseAddress>
>() => {
  const queryClient = useQueryClient()
  // if user is not already marked as do not share, tell backend to check
  const checkUserOptOut = !userIsDoNotShareSellTA()
  return useMutation({
    mutationFn: (address: TAddress) =>
      apiPatch<AddressMutationBffResponse>({
        config: {
          url: `member-web-bff/v1/address/${address.id}`,
          data: { address, check_user_opt_out: checkUserOptOut },
        },
        options: {
          ignoredMessages: [
            /address not in service area/i,
            'delivery instructions must be 400 characters or less',
          ],
        },
        fetcherName: 'useMutationUpdateAddressBff',
        tag: 'bff',
      }),
    onSuccess: onOptOutSuccessHandler(queryClient),
  })
}

export const useMutationDeleteAddress = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (addressId: number) =>
      apiDelete<DeleteAddressResponse>({
        config: {
          url: `api/v1/customer_addresses/${addressId}`,
        },
        fetcherName: 'useMutationDeleteAddress',
      }),
    onSuccess: (_, addressId) => {
      queryClient.setQueryData(addressQueryOptions.queryKey, (addresses) =>
        addresses?.filter((address) => address.id !== addressId)
      )
    },
  })
}
